import React from "react"
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from 'gatsby'
import { getServiceDelay } from '../utils/transitions'
import Transitions from '../components/transitions'
import GreenDot from '../components/green-dot'

const Successes = ({ content, projects }) => {
  if (!content) return null
  return (
    <>
      <SEO 
        image={projects[0].node.front_image.url+"&w=1200"}
        title={RichText.asText(content.seo_title)}
        keywords={RichText.asText(content.seo_keywords)}
        description={RichText.asText(content.seo_description)}
        url="https://bonsai.is/successes"
      />
      <div className="full_banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-11">
              <Transitions.SlideUp>
                <h1>{RichText.asText(content.title)}<GreenDot/></h1>
              </Transitions.SlideUp>
            </div>
          </div>
        </div>
      </div>

      <div className="information_area">
        <div className="container">
          <div className="information_inner">
            <div className="information_text mb-0">
              <Transitions.SlideUp>
                <p>{RichText.asText(content.header_text)}</p>
              </Transitions.SlideUp>
            </div>
          </div>
        </div>
      </div>

      <div className="projects_list">
        <div className="container">
          {projects.map((project) => {
            return(
              <div className="project">           
                <div className="project_image">
                  <Link to={"/successes/"+project.node._meta.uid}><Img fluid={project.node.front_imageSharp.childImageSharp.fluid} alt={project.node.front_image.alt}/></Link>
                </div>
                <div className="project_info align-items-center d-flex flex-wrap flex-md-nowrap">
                  <h2>{RichText.asText(project.node.title)}</h2>
                  <Link to={"/successes/"+project.node._meta.uid} className="btn p-0 ml-auto btn-link"><img src="../../arrow.svg" className="arrow-left" alt="arrow"/> read study</Link>
                </div>
                <ul className="project_services d-none d-md-flex align-items-center list-unstyled">
                  {project.node.services.map((service, index) => (
                    <li className='side-text'>
                      <Transitions.SlideUp
                        delay={getServiceDelay(index)}
                        duration={"250"}
                      >
                        {service.text}
                      </Transitions.SlideUp>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ({ data }) => {
  const content = data.prismic.allSuccessess.edges[0].node
  const projects = data.prismic.allProjects.edges
  if (!content) return null
  return (
    <Layout>
      <Successes content={content} projects={projects}/>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allSuccessess {
        edges {
          node {
            seo_description
            seo_keywords
            header_text
            seo_title
            title
          }
        }
      }
      allProjects(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            date
            title
            services
            front_image
            front_imageSharp {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`